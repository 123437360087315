import React from 'react';
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import FormLayoutGroup from "@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import bridge from '@vkontakte/vk-bridge';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import {Checkbox, Link, Select} from "@vkontakte/vkui";
import API from "../utils/API";
import CardGrid from "@vkontakte/vkui/dist/components/CardGrid/CardGrid";
import Card from "@vkontakte/vkui/dist/components/Card/Card";
import FormHead from "./FormHead";
import SubMessage from "./SubMessage";
import FormError from "./FormError";
import InputPhone from "./InputPhone";
import ReactTextFormat from 'react-text-format';
import Grid from "@material-ui/core/Grid";
import CityList from "./CityList";

export default class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: this.props.store.userReducer.userInfo.full_name === null ? '' : this.props.store.userReducer.userInfo.full_name,
            phone: this.props.store.userReducer.userInfo.phone === null ? '' : this.props.store.userReducer.userInfo.phone,
            city: this.props.store.userReducer.userInfo.city === null ? '' : this.props.store.userReducer.userInfo.city,
            avatar: this.props.store.userReducer.userInfo.photo_100,
            isLoading: true,
            errors: [],
            isFinished: false,
            hash: null,
            allow_messages: false,
            group_id: this.props.store.userReducer.app.group_id,
            confirm: true
        }
    }

    componentDidMount() {
        bridge.subscribe(({detail: {type, data}}) => {
            switch (type) {
                case 'VKWebAppGetPhoneNumberFailed':
                    this.props.dispatch({
                        type: 'HANDLE_SET_USER_PHONE',
                        data: ''
                    });

                    this.setState({
                        phone: '',
                        isLoading: false
                    })
                    break;
                case 'VKWebAppGetPhoneNumberResult':
                    this.props.dispatch({
                        type: 'HANDLE_SET_USER_PHONE',
                        data: data.phone_number
                    });
                    this.setState({
                        phone: data.phone_number,
                        isLoading: false
                    });
                    break;
                default:
                    break;
            }
        });

        if (this.props.store.userReducer.userInfo.phone === null && this.props.store.userReducer.app.mode === 'vk') {
            bridge.send("VKWebAppGetPhoneNumber", {});
        } else {
            this.setState({
                phone: '',
                isLoading: false
            })

        }
    }

    handleFullName = (e) => {
        this.props.dispatch({
            type: 'HANDLE_SET_FULL_NAME',
            data: e.target.value
        });

        this.setState({fullName: e.target.value, errors: []})
    }

    handlePhone = (e) => {

        let phone = e.target.value.replace(/\D/g, '').substr(0, 11);

        this.props.dispatch({
            type: 'HANDLE_SET_USER_PHONE',
            data: phone
        });

        //  console.log("phone");
        //console.log(phone);
        this.setState({
            phone: phone,
            errors: []
        })
    }

    handleCity = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_USER_CITY',
            data: e.target.value
        });
        this.setState({city: e.target.value, errors: []})
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let errors = [];


        if (this.state.phone === '' || this.state.phone === null) {
            errors.push(['Необходимо корректно ввести номер телефона']);
        }


        if (this.state.phone !== null && this.state.phone.length !== 11) {
            errors.push(['Номер телефона должен состоять из 11 цифр']);
        }

        if (this.state.city === '' || this.state.city === null) {
            errors.push(['Необходимо корректно город']);
        }


        this.setState({
            errors: [...this.state.errors, ...errors]
        });

        if (errors.length === 0) {
            this.setState({
                isLoading: true
            });

            await API.post(process.env.REACT_APP_API_URL + '/app/send', {
                    group_id: this.props.store.userReducer.app.group_id,
                    user_id: this.props.store.userReducer.userInfo.id,
                    full_name: this.props.store.userReducer.userInfo.full_name,
                    phone: this.props.store.userReducer.userInfo.phone,
                    city: this.props.store.userReducer.userInfo.city,
                    hash: this.props.store.userReducer.app.hash,
                    contest_id: this.props.store.userReducer.app.contest.id,
                    source: this.props.store.userReducer.app.mode,
                    app_id: process.env.REACT_APP_VK_APP_ID
                }
            ).then(res => {
                this.props.dispatch({
                    type: 'HANDLE_SET_HASH',
                    hash: res.data.result.hash
                });
                this.props.dispatch({
                    type: 'HANDLE_SET_ALLOW',
                    data: res.data.result.allow_messages
                })
                this.props.dispatch({
                    type: 'HANDLE_SET_UUID',
                    data: res.data.result.uuid
                })

                this.setState({
                    isLoading: false,
                    isFinished: true,
                    hash: res.data.hash,
                    allow_messages: res.data.allow_messages,
                    groupId: res.data.result.group_id
                });

            });

        }
    }


    ThxMessage = () => {
        return <Div>
            <CardGrid>
                <Card size="l" mode="shadow">
                    <FormHead cover={this.props.store.userReducer.app.contest.cover}/>
                    <div style={{marginLeft: 5, marginRight: 5}}>
                        <SubMessage dispatch={this.props.dispatch}
                                    contest_id={this.props.store.userReducer.app.contest.id}
                                    disable_verify={this.props.store.userReducer.app.contest.disable_verify}
                                    disable_mlm={this.props.store.userReducer.app.contest.disable_mlm}
                                    store={this.props.store}/>
                    </div>
                </Card>
            </CardGrid>
        </Div>
    }

    customLinkDecorator = (
        decoratedHref: string,
        decoratedText: string,
        linkTarget: string,
        key: number
    ): React.Node => {
        return (
            <a
                href={decoratedHref}
                key={key}
                target={linkTarget}
                rel='noopener'
                className='customLink'
            >
                {decoratedText}
            </a>
        )
    }

    Form = () => {
        if (this.props.store.userReducer.app.contest.active === 0) {
            return <FormError error={['Конкурс не активен']}/>
        }
        // if(this.state.phone=='')
        return <Div>
            <CardGrid>
                <Card size="l" mode="shadow">
                    <FormHead cover={this.props.store.userReducer.app.contest.cover}/>
                    <Div style={{marginLeft: 5, marginRight: 5, whiteSpace: 'pre-line'}}>
                        <ReactTextFormat allowedFormats={['URL']} linkTarget={'_blank'}>
                            {this.props.store.userReducer.app.contest.description}
                        </ReactTextFormat>
                    </Div>
                    <FormLayoutGroup>
                        <FormLayout onSubmit={this.handleSubmit}>
                            {this.state.errors.length === 0 ? null : <FormError error={this.state.errors}/>}
                            <Input top={'Имя'} type="text" value={this.state.fullName}
                                   onChange={this.handleFullName}
                            />
                            <InputPhone
                                placeholder="7__________"
                                top={'Телефон'}
                                type="text"
                                value={this.state.phone}
                                onChange={this.handlePhone}
                                disabled={false}
                            />
                            {this.props.store.userReducer.app.mode === 'web' ?

                                <Select type="text" onChange={this.handleCity} top={'Город'}>
                                    <option value="" disabled>выберите из списка</option>
                                    <option value="Другой">Другой</option>
                                    <CityList cities={this.props.store.userReducer.app.cities}/>
                                    <option value="Другой">Другой</option>
                                </Select>
                                :
                                <Input top={'Город'} type="text" value={this.state.city}
                                       onChange={this.handleCity}/>
                            }


                            {this.props.store.userReducer.app.mode === 'web' ?
                                <Div>
                                    <Checkbox
                                        checked={this.state.confirm}
                                        onChange={() => {this.setState({confirm: !this.state.confirm})}}
                                    >Я согласен с условиями <Link href={'https://priziva.net/privacy.php'}
                                                                  target={'_blank'}>обработки персональных данных</Link>
                                    </Checkbox>
                                </Div>
                                : null}
                            <Div>
                                <Button size="xl" style={{cursor: 'pointer'}} disabled={!this.state.confirm}>Отправить</Button>
                            </Div>
                        </FormLayout>
                    </FormLayoutGroup>
                </Card>
            </CardGrid>
        </Div>;
    }

    render() {
        if (this.state.isLoading) {
            return <ScreenSpinner/>;
        }

        return <Panel id={"form"}>
            {this.props.store.userReducer.userInfo.is_admin ?
                <Div>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Button size={'xl'}
                                    onClick={() => this.props.navigator.go("manage_contest", {
                                        id: this.props.store.userReducer.app.contest.id,
                                    })}
                            >Управление</Button>
                        </Grid>
                    </Grid>
                </Div>
                : null}

            {this.state.isFinished ? <this.ThxMessage/> : <this.Form/>}
            {/*{!this.props.store.userReducer.userInfo.is_admin ? null :*/}
            {/*    <List>*/}
            {/*        <CellButton onClick={() => this.props.navigator.go("friendList")}>Отправить друзьям</CellButton>*/}
            {/*        <CellButton onClick={this.handleWallPost}>Разместить на стене</CellButton>*/}
            {/*        <CellButton onClick={() => this.props.navigator.go('cabinet')}>Личный кабинет</CellButton>*/}
            {/*    </List>*/}
            {/*}*/}

        </Panel>
    }

}
