import React from 'react';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import PanelHeaderContent from "@vkontakte/vkui/dist/components/PanelHeaderContent/PanelHeaderContent";
import logo from "../img/prizyvanetlogo.png";

export default class Home extends React.Component{

	constructor(props) {
		super(props);
	}

	render(){
		return <Panel id={"home"}>
			<PanelHeader><PanelHeaderContent
				before={<Avatar size={36} src={this.props.store.userReducer.userInfo.photo_100}/>}
			><img className="logo" src={logo} alt={''}/></PanelHeaderContent></PanelHeader>
			<Div>
				<p>Нужен военный билет?</p>

				<p>Два клика мышкой – и мы запишем тебя в розыгрыш договора!</p>

				<p>Если твой выбор свобода – прыгай на борт. Используй свой шанс законно и бесплатно освободиться от армии.</p>
			</Div>
		</Panel>
	}
}
