import React from 'react';
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import bridge from "@vkontakte/vk-bridge";
import {Link, ScreenSpinner} from "@vkontakte/vkui";
import API from "../utils/API";
import ReactTextFormat from 'react-text-format';
import templateVar from "../utils/templateVars";



export default class SubMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    setAllow = (res) => {
        this.props.dispatch({
            type: 'HANDLE_SET_ALLOW',
            data: res
        });
    }

    repeatSendForm = () => {
        return API.post(process.env.REACT_APP_API_URL + '/app/send', {
                group_id: this.props.store.userReducer.app.group_id,
                user_id: this.props.store.userReducer.userInfo.id,
                full_name: this.props.store.userReducer.userInfo.full_name,
                phone: this.props.store.userReducer.userInfo.phone,
                city: this.props.store.userReducer.userInfo.city,
                hash: this.props.store.userReducer.app.hash,
                contest_id: this.props.store.userReducer.app.contest.id,
                source: this.props.store.userReducer.app.mode,
                app_id: process.env.REACT_APP_VK_APP_ID,
                uuid: this.props.store.userReducer.userInfo.uuid
            }
        );
    }

    allowMessagesFromGroup = () => {
        this.setState({isLoading: true});
        bridge.send('VKWebAppAllowMessagesFromGroup', {
            group_id: Number(process.env.REACT_APP_GROUP_OWNER)
        }).then(res => {
            if (res.result) {
                this.repeatSendForm().then(r => {
                    this.props.dispatch({
                        type: 'HANDLE_SET_HASH',
                        hash: r.data.result.hash
                    });
                    this.props.dispatch({
                        type: 'HANDLE_SET_ALLOW',
                        data: r.data.result.allow_messages
                    });
                    this.props.dispatch({
                        type: 'HANDLE_SET_UUID',
                        data: r.data.result.uuid
                    });
                    this.setAllow(r.data.result.allow_messages);
                    this.setState({isLoading: false});
                });
            } else {
                this.setState({isLoading: false});
            }
        });
    }

    handleLink = (url) => {
        //e.preventDefault();
        window.open(url);
    }

    render() {
        if (this.isLoading) {
            return <ScreenSpinner/>
        }
        if (this.props.store.userReducer.app.mode === 'vk') {
            if (this.props.store.userReducer.userInfo.allow_messages === 0) {
                return <Div style={{marginLeft: 5, marginRight: 5}}>
                    <p>Разрешите сообщение от ПризываНет, что бы получать информацию об итогах конкурса</p>
                    <Button size="xl" onClick={this.allowMessagesFromGroup}>Подписаться на сообщения
                        сообщества</Button>
                </Div>;
            } else {
                return <Div style={{marginLeft: 5, marginRight: 5}}>
                    <p style={{textAlign: 'center', whiteSpace: 'pre-line'}}>
                        <ReactTextFormat allowedFormats={['URL']} linkTarget={'_blank'}>
                            {this.props.store.userReducer.app.contest.thx_text}
                        </ReactTextFormat>
                    </p>
                    {this.props.disable_mlm === 0 ?
                        <>
                            <p style={{textAlign: 'center'}}>
                                Твоя ссылка для распостранения
                            </p>
                            <p style={{textAlign: 'center'}}>
                                <Link
                                    href={'https://vk.com/app' + process.env.REACT_APP_VK_APP_ID + '_-' + process.env.REACT_APP_GROUP_OWNER + '#' + '/id' + this.props.contest_id + '&' + this.props.store.userReducer.app.hash}
                                    target={'_blank'}>
                                    {'https://vk.com/app' + process.env.REACT_APP_VK_APP_ID + '_-' + process.env.REACT_APP_GROUP_OWNER + '#' + '/id' + this.props.contest_id + '&' + this.props.store.userReducer.app.hash}
                                </Link>
                            </p>
                        </>
                        : null}

                    {this.props.disable_verify === 1 ? null :
                        <>
                            <p style={{textAlign: 'center'}}>
                                Для участия необходимо пройти верификацию
                            </p>
                            <p style={{textAlign: 'center'}}>
                                <Button size="xl"
                                        onClick={() => this.handleLink(`https://vk.com/write-${process.env.REACT_APP_GROUP_OWNER}?ref=${this.props.store.userReducer.userInfo.uuid}`)}>{this.props.store.userReducer.app.contest.verify_btn_name}</Button>
                            </p>
                        </>
                    }
                    {this.props.store.userReducer.app.contest.custom_btn === 1 ?
                        <p style={{textAlign: 'center'}}>
                            <Button style={{cursor: 'pointer'}} size="xl"
                                    onClick={() => this.handleLink(new templateVar().setVars(this.props.store.userReducer.app.contest.custom_btn_link))}>{this.props.store.userReducer.app.contest.custom_btn_name}</Button>
                        </p>

                        : null}
                </Div>
            }
        } else {

            return <Div style={{marginLeft: 5, marginRight: 5}}>
                <p style={{textAlign: 'center'}}>
                    {this.props.store.userReducer.app.contest.thx_text}
                </p>
                {this.props.disable_mlm === 0 ?
                    <>
                        <p style={{textAlign: 'center'}}>
                            Твоя ссылка для распостранения
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <Link
                                href={`${window.location.origin}/id${this.props.contest_id}#&${this.props.store.userReducer.app.hash}`}
                                target={'_blank'}>{`${window.location.origin}/id${this.props.contest_id}#&${this.props.store.userReducer.app.hash}`}
                            </Link>
                        </p>
                    </>
                    : null
                }
                {this.props.disable_verify === 0 ?
                    <>
                        <p style={{textAlign: 'center'}}>
                            Для участия необходимо пройти верификацию по ссылке
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <Button style={{cursor: 'pointer'}} size="xl"
                                    onClick={() => this.handleLink(`https://t.me/${this.props.store.userReducer.app.contest.bot_name}?start=${this.props.store.userReducer.userInfo.uuid}`)}>{this.props.store.userReducer.app.contest.verify_btn_name}</Button>
                        </p>
                    </>
                    : null
                }
                {this.props.store.userReducer.app.contest.custom_btn === 1 ?
                    <p style={{textAlign: 'center'}}>
                        <Button style={{cursor: 'pointer'}} size="xl"
                                onClick={() => this.handleLink(new templateVar().setVars(this.props.store.userReducer.app.contest.custom_btn_link))}>{this.props.store.userReducer.app.contest.custom_btn_name}</Button>
                    </p> : null
                }

            </Div>

        }

    }
}
