import "core-js/features/map";
import "core-js/features/set";
import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import store from './redux/store-redux';
import 'react-datepicker/dist/react-datepicker.css';


let renderEntireTree = () => {

  bridge.send("VKWebAppInit");
  ReactDOM.render(<App store={store.getState()} dispatch={store.dispatch.bind(store)} />, document.getElementById("root"));
  if (process.env.NODE_ENV === "develop" || process.env.NODE_ENV === "local") {
    import("./eruda").then(({ default: eruda }) => {});
  }
}


renderEntireTree(store.getState());
store.subscribe(renderEntireTree);

serviceWorker.unregister();
