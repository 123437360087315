import React from 'react';
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import API from "../utils/API";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import List from "@vkontakte/vkui/dist/components/List/List";
import Header from "@vkontakte/vkui/dist/components/Header/Header";
import ListContests from "./ListContests";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import {SimpleCell} from "@vkontakte/vkui";
import { Icon24SortOutline } from '@vkontakte/icons';


export default class Contests extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contests: [],
            amo_data: [],
            isLoading: true,
            sort: 'ASC'
        }
    }

    handleSort = () => {
        this.setState({isLoading: true});
        let sort  = this.state.sort === 'ASC'? 'DESC' : 'ASC';
        this.setState({sort: sort});
        this.getContests();
    }

    getContests = () => {
        API.get(`/contest/sort/${this.state.sort}`, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            this.setState({
                isLoading: false,
                amo_data: res.data.amo_data,
                contests: res.data.contest
            });
        });
    }

    componentDidMount() {
        this.getContests();
    }

    render() {
        return <Panel id={"contests"}>
            <Div>
                <Button size="xl"
                        onClick={() => this.props.navigator.go("create_contest", {"amo_data": this.state.amo_data})}>Создать
                    новый</Button>
            </Div>
            {this.state.isLoading ? <ScreenSpinner/> :
                <Group header={<Header mode="secondary">Список конкурсов</Header>}>
                    <SimpleCell
                        before={'Сортировка'}
                        after={<Icon24SortOutline onClick={this.handleSort}/>}
                    />
                    <List>
                        <ListContests contests={this.state.contests}
                                      navigator={this.props.navigator}/>
                    </List>
                </Group>
            }
        </Panel>
    }
}
