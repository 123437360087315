import React from 'react';
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import InputMask from "react-input-mask";

export default function InputPhone(props) {


    if (window.global && window.global.vk_app_id) {
        return <Input placeholder={props.placeholder}
                      disabled={props.disabled}
                      top={'Телефон'}
                      type="text"
                      value={props.value}
                      onChange={props.onChange}/>;
    } else {
        // return <Input placeholder={props.placeholder} top={'Телефон'} type="text" value={props.phone}
        //               onChange={props.onChange}/>;

        return <InputMask
            style={{
                marginLeft: "12px",
                marginRight: "20px !important",
                width: "97%",
                background: "#f2f3f5",
                border: "1px solid var(--field_border)",
                borderRadius: "10px"
            }}
            required='required'
            alwaysShowMask={true} type="text" className="Input__el"
            mask="+7999 999 99 99"
            value={props.value}
            onChange={props.onChange}>
        </InputMask>;
    }


}
