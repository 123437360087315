import React from 'react';
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import {Switch} from "@vkontakte/vkui";
import Link from "@vkontakte/vkui/dist/components/Link/Link";
import Chip from "@material-ui/core/Chip";
import Badge from '@material-ui/core/Badge';
import {withStyles} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Typography from "@material-ui/core/Typography";

const classes = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 10,
        margin: 0,
    },
    chip: {
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 0,
        marginRight: 3,
        paddingLeft: 5,
        paddingRight: 5
    },
};

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

export default class ListContests extends React.Component {
    constructor(props) {
        super(props);

    }

    Tags = (props) => {
        return props.tags.map((item, index) => {
            return <li key={index}>
                <Chip
                    label={item}
                    style={classes.chip}
                />
            </li>
        })
    }

    render() {
        return this.props.contests.map(item => {
            return <SimpleCell
                key={item.id}
                after={
                    <ul style={classes.root}>
                        <this.Tags tags={item.tags.split(',')}/>
                    </ul>
                    }
                before={`#${item.id}`}
            >
                <Link onClick={() => this.props.navigator.go("user_contest", {
                    id: item.id,
                    amo_data: this.props.amo_data
                })}>
                    <StyledBadge
                        badgeContent={item.leads_cnt}
                        max={10000}
                        color="secondary">
                        <PersonIcon/>
                    </StyledBadge>
                </Link>
                <Typography
                    variant="h6"
                    display={'inline'}
                    style={{marginLeft: 30}}

                ><Link
                    style={!item.active? {color: '#CCC'}: null}
                    href={'https://vk.com/app' + process.env.REACT_APP_VK_APP_ID + '_-' + process.env.REACT_APP_GROUP_OWNER + '#' + '/id' + item.id + '&panel=manage_contest'}
                    target={'_blank'}
                >{item.name}</Link>

                </Typography>
            </SimpleCell>
        });
    }
}
