import store from '../redux/store-redux';

export default class templateVars {

    constructor() {
        this.store = store.getState();
        this.templates = [
            {name: '@uid', val: this.store.userReducer.userInfo.uuid},
            {name: '@phone', val: this.store.userReducer.userInfo.phone},
            {name: '@city', val: this.store.userReducer.userInfo.city},
            {name: '@vkid', val: this.store.userReducer.userInfo.id},
            {name: '@name', val: this.store.userReducer.userInfo.full_name},
        ];
    }

    setVars = (string) => {
        this.templates.map((item) => {
            if(item.val !== null){
                string = string.split(item.name).join(item.val);
            }else{
                string = string.split(item.name).join('');
            }

        });
        return string;
    }

}
