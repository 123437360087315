import React from 'react';
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import FormLayoutGroup from "@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup";
import Textarea from "@vkontakte/vkui/dist/components/Textarea/Textarea";
import Icon24Document from "@vkontakte/icons/dist/24/document";
import File from "@vkontakte/vkui/dist/components/File/File";
import Switch from "@vkontakte/vkui/dist/components/Switch/Switch";
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";
import API from "../utils/API";
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import {Group, Select} from "@vkontakte/vkui";
import FormError from "./FormError";
import {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Icon24GearOutline, Icon24Linked, Icon24List, Icon28LogoInstagram, Icon28LogoVkOutline} from '@vkontakte/icons';
import coverImage from "../img/blank.png";
import TabPanel from "./TabPanel";

export default class ManageContest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contest_id: this.props.navigator.params.id,
            amo_user_id: '',
            amo_pipeline_id: '',
            amo_status_id: '',
            file: '',
            imagePreviewUrl: coverImage,
            photos:[],
            files:[],
            isLoading: true,
            description: '',
            thx_text: '',
            welcome_message: '',
            active: true,
            days: this.getInitialState(),
            start_date: '',
            end_date: '',
            name: '',
            isError: false,
            errorText: '',
            disable_verify: false,
            iscoverdynamic:false,
            disable_mlm: false,
            verify_btn_name: '',
            tab: 1,
            bot_name: '',
            custom_btn: false,
            custom_btn_name: '',
            custom_btn_link: '',
            amo_data: [],
        }

        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleAmoUser = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_USER_ID',
            data: e.target.value
        });
        this.setState({amo_user_id: e.target.value});
    }

    handleAmoPipeline = (e) => {
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_PIPELINE_ID',
            data: e.target.value
        });
        this.setState({amo_pipeline_id: e.target.value});
    }
    handleAmoStatus = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_STATUS_ID',
            data: e.target.value
        });
        this.setState({amo_status_id: e.target.value});
    }

    componentDidMount() {
        if(this.state.contest_id === undefined){
            this.state.contest_id = this.props.store.userReducer.app.contest.id;
        }

        API.get('/contest/' + this.state.contest_id, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {

            if(res.data.contest.attachments === undefined){
                res.data.contest.attachments=[];
            }
            if(res.data.contest.files === undefined){
                res.data.contest.files=[];
            }

            this.setState({
                amo_user_id: res.data.contest.amo_user_id,
                amo_pipeline_id: res.data.contest.amo_pipeline_id,
                amo_status_id: res.data.contest.amo_status_id,
                isLoading: false,
                contest: res.data.contest,
                description: res.data.contest.description,
                thx_text: res.data.contest.thx_text,
                welcome_message: res.data.contest.welcome_message,
                active: res.data.contest.active,
                disable_verify: res.data.contest.disable_verify,
                iscoverdynamic:res.data.contest.iscoverdynamic,
                disable_mlm: res.data.contest.disable_mlm,
                start_date: moment(res.data.contest.start_date).format('YYYY/MM/DD'),
                end_date: moment(res.data.contest.end_date).format('YYYY/MM/DD'),
                name: res.data.contest.name,
                tags: res.data.contest.tags,
                first_tags: res.data.contest.first_tags,
                attributio: res.data.contest.attributio,
                utm_source: res.data.contest.utm_source,
                utm_medium: res.data.contest.utm_medium,
                utm_campaign: res.data.contest.utm_campaign,
                utm_content: res.data.contest.utm_content,
                utm_term: res.data.contest.utm_term,
                photos:res.data.contest.attachments,
                files:res.data.contest.files,
                imagePreviewUrl: res.data.contest.cover,
                days: {
                    from: moment(res.data.contest.start_date).toDate(),
                    to: moment(res.data.contest.end_date).toDate()
                },
                verify_btn_name: res.data.contest.verify_btn_name,
                bot_name: res.data.contest.bot_name,
                custom_btn: res.data.contest.custom_btn,
                custom_btn_name: res.data.contest.custom_btn_name,
                custom_btn_link: res.data.contest.custom_btn_link,
                amo_data: res.data.amo_data
            });
        });
    }

    handleLinkDocWebhook = (e) => {
        e.preventDefault();
        API.get(process.env.REACT_APP_API_URL + '/doc/' + this.state.contest_id, {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': `Bearer ${this.props.store.userReducer.userInfo.token}`
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `documentation_for_webhook_` + this.state.contest.id + ".txt",
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }
    handleLinkInstagram = (e) => {
        e.preventDefault();
        let usuk_url = window.location.origin;
        if(process.env.NODE_ENV === 'production'){
            usuk_url = 'https://reg.pr.priziva.net';
        }
        window.open(`${usuk_url}/id${this.state.contest_id}`, '_blank');

    }
    handleLinkVk = (e) => {
        e.preventDefault();
        const full_url = `https://vk.com/app${process.env.REACT_APP_VK_APP_ID}_-${process.env.REACT_APP_GROUP_OWNER}#/id${this.state.contest_id}`;
        window.open(full_url, '_blank');
    }

    handleDelete = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        API.get('/contest/delete/' + this.state.contest_id, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    isLoading: false
                });
                this.props.navigator.go("contests");
            }
        });
    }

    handleSubmit = (e) => {
        this.setState({
            isLoading: true
        });
        e.preventDefault();
        API.post('/contest/update', {
            amo_user_id: this.state.amo_user_id,
            amo_pipeline_id: this.state.amo_pipeline_id,
            amo_status_id: this.state.amo_status_id,
            id: this.state.contest_id,
            name: this.state.name,
            description: this.state.description,
            thx_text: this.state.thx_text,
            tags: this.state.tags,
            first_tags: this.state.first_tags,
            attributio: this.state.attributio,
            utm_source: this.state.utm_source,
            utm_medium: this.state.utm_medium,
            utm_campaign: this.state.utm_campaign,
            utm_content: this.state.utm_content,
            utm_term: this.state.utm_term,
            date_start: moment(this.state.start_date).format('YYYY-MM-DD 00:00:00'),
            date_end: moment(this.state.end_date).format('YYYY-MM-DD 23:59:59'),
            active: this.state.active,
            disable_verify: this.state.disable_verify,
            disable_mlm: this.state.disable_mlm,
            iscoverdynamic:this.state.iscoverdynamic,
            welcome_message: this.state.welcome_message,
            cover: this.state.imagePreviewUrl,
            verify_btn_name: this.state.verify_btn_name,
            bot_name: this.state.bot_name,
            custom_btn: this.state.custom_btn,
            custom_btn_name: this.state.custom_btn_name,
            custom_btn_link: this.state.custom_btn_link,
        },{
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            if (res.data.success) {
                this.props.navigator.go("contests");
                this.setState({
                    isLoading: false
                });
            } else {
                this.setState({
                    isError: true,
                    isLoading: false,
                    errorText: res.data.error
                });
            }
        }).catch(e => {
            console.log(e);
        });
    }


    handleFileDelete =(e,index)=>{

        console.log('delete photo');
        API.get("/contest/remove_file/"+this.state.contest_id+"/"+index,{
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res=>{
            if(res.data.success){

                this.setState({
                    files:res.data.files
                });
            }else {
                console.log(res.data);
            }
        }).catch(e => {
            console.log(e);
        });

        return false;
    }
    handlePhotoDelete =(e,index)=>{

        console.log('delete photo');
        API.get("/contest/remove_vk_photo/"+this.state.contest_id+"/"+index, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res=>{
            if(res.data.success){

                this.setState({
                    photos:res.data.attachments
                });
            }else {
                console.log(res.data);
            }
        }).catch(e => {
            console.log(e);
        });

        return false;
    }
    _handleFileUpload= (e) =>{
        e.preventDefault();

        const formData = new FormData();
        let file = e.target.files[0];
        formData.append(
            "file",
            file,
            file.name
        );
        API.post('/contest/upload_file/'+this.state.contest_id, formData, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {

            if(res.data.success){

                this.setState({
                    files:res.data.files
                });
            }else {
                console.log(res.data);
            }
            //
        }).catch(e => {
            console.log(e);
        });
    }
    _handlePhotoUpload= (e) =>{
        e.preventDefault();

        const formData = new FormData();
        let file = e.target.files[0];
        formData.append(
            "photo",
            file,
            file.name
        );
        API.post('/contest/upload_vk_photo/'+this.state.contest_id, formData, {
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {

            if(res.data.success){

                this.setState({
                    photos:res.data.attachments
                });
            }else {
                console.log(res.data);
            }
           //
        }).catch(e => {
            console.log(e);
        });
    }
    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    handleDisableVerify = () => {
        this.setState({disable_verify: !this.state.disable_verify});
    }
    handleIscoverDynamic=()=>{
        this.setState({iscoverdynamic: !this.state.iscoverdynamic});
    }

    handleDisableMlm = () => {
        this.setState({disable_mlm: !this.state.disable_mlm});
    }

    handleActive = () => {
        this.setState({active: !this.state.active});
    }

    handleDayClick(day) {
        console.log('day', day);
        //this.setState({days: this.getInitialState()})
        const range = DateUtils.addDayToRange(day, {from: this.state.days.from, to: this.state.days.to});
        console.log('range', range);

        this.setState(range);
    }

    handleResetClick() {
        this.setState(
            {days: this.getInitialState()}
        );
    }

    handleTab = (event, value) => {
        this.setState({tab: value});
    }

    render() {
        const {from, to} = this.state.days;
        const modifiers = {start: from, end: to};
        return <Panel id={"manage_contest"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={this.state.tab} onChange={this.handleTab}
                    aria-label="simple tabs example"
                    variant="fullWidth"
                    indicatorColor="primary"
                >
                    <Tab onClick={() => this.props.navigator.go("contests")} icon={<Icon24BrowserBack/>}/>
                    <Tab icon={<Icon24List/>}/>
                    <Tab icon={<Icon24GearOutline/>}/>
                    <Tab icon={<Icon24Linked/>}/>
                </Tabs>
            </AppBar>
            <FormLayoutGroup>
                {this.state.isLoading ? <ScreenSpinner/> :
                    <FormLayout onSubmit={this.handleSubmit}>
                        <TabPanel index={3} value={this.state.tab}>
                            <Group mode="plain">
                            <SimpleCell>
                                <Button size="xl" mode="secondary" onClick={this.handleLinkVk}
                                        before={<Icon28LogoVkOutline/>}>Ссылка для VK</Button>
                            </SimpleCell>
                            <SimpleCell>
                                <Button size="xl" mode="secondary" onClick={this.handleLinkInstagram}
                                        before={<Icon28LogoInstagram/>}> Ссылка для
                                    Instagram</Button>
                            </SimpleCell>
                            </Group>
                            <Group mode="plain">
                            <SimpleCell>
                                <Div><Text weight="regular">VK Callback URL</Text></Div>
                                <Input disabled top="VK Callback URL" type="text"
                                       value={process.env.REACT_APP_API_URL + '/hook/' + this.state.contest_id + '/confirm/{code}'}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                <Div><Text weight="regular">URL WebHook</Text></Div>
                                <Input disabled top="URL WebHook" type="text"
                                       value={process.env.REACT_APP_API_URL + '/hook/' + this.state.contest_id + '/update'}
                                />
                            </SimpleCell>
                            </Group>
                            <Group mode="plain">
                            <SimpleCell>
                                <Button size="xl" mode="secondary" onClick={this.handleLinkDocWebhook}>Документация для
                                    webhook</Button>

                            </SimpleCell>
                            </Group>
                        </TabPanel>
                        <TabPanel index={1} value={this.state.tab}>
                            <SimpleCell>
                                <Div><Text weight="regular">Название</Text></Div>
                                <Input top="Название" type="text"
                                       value={this.state.name}
                                       onChange={(e) => this.setState({name: e.target.value})}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                <Div><Text weight="regular">Описание</Text></Div>
                                <Textarea top="Описание"
                                          value={this.state.description}
                                          onChange={(e) => this.setState({description: e.target.value})}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                {this.state.imagePreviewUrl !== '' ?
                                    <Div><img src={this.state.imagePreviewUrl} alt={'Обложка конкурса'}
                                              style={{width: '100%'}}/></Div> : null}
                                <File top="Загрузите обложку (jpg)"
                                      before={<Icon24Document/>}
                                      controlSize="xl"
                                      onChange={(e) => this._handleImageChange(e)}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                <Div><Text weight="regular">Текст кнопки верификации</Text></Div>
                                <Input top="Текст кнопки верификации"
                                       type="text"
                                       value={this.state.verify_btn_name}
                                       onChange={(e) => this.setState({verify_btn_name: e.target.value})}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                <Div><Text weight="regular">Тэги</Text></Div>
                                <Input top="Тэги" type="text"
                                       value={this.state.tags}
                                       onChange={(e) => this.setState({tags: e.target.value})}
                                />
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">1st Теги</Text>
                                </Div>
                                <Input top="1st Теги" type="text" value={this.state.first_tags} onChange={(e) => this.setState({first_tags: e.target.value})}/>
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">Attributio</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.attributio} onChange={(e) => this.setState({attributio: e.target.value})}/>
                            </SimpleCell>

                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">utm_source</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.utm_source} onChange={(e) => this.setState({utm_source: e.target.value})}/>
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">utm_medium</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.utm_medium} onChange={(e) => this.setState({utm_medium: e.target.value})}/>
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">utm_campaign</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.utm_campaign} onChange={(e) => this.setState({utm_campaign: e.target.value})}/>
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">utm_content</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.utm_content} onChange={(e) => this.setState({utm_content: e.target.value})}/>
                            </SimpleCell>
                            <SimpleCell>
                                <Div>
                                    <Text weight="regular">utm_term</Text>
                                </Div>
                                <Input top="Attributio" type="text" value={this.state.utm_term} onChange={(e) => this.setState({utm_term: e.target.value})}/>
                            </SimpleCell>

                            <SimpleCell>
                                <Div><Text weight="regular">Текст после отправки</Text></Div>
                                <Textarea top="Текст после отправки"
                                          value={this.state.thx_text}
                                          onChange={(e) => this.setState({thx_text: e.target.value})}
                                />
                            </SimpleCell>


                            {/*<Div>*/}
                            {/*    Даты проведения конкурса {this.state.start_date} - {this.state.end_date}*/}
                            {/*    <DayPicker*/}
                            {/*        className="Selectable"*/}
                            {/*        numberOfMonths={2}*/}
                            {/*        selectedDays={[from, { from, to }]}*/}
                            {/*        modifiers={modifiers}*/}
                            {/*        onDayClick={this.handleDayClick}*/}
                            {/*    />*/}
                            {/*</Div>*/}
                            <SimpleCell>
                                <Div><Text weight="regular">Приветственное сообщение в диалоге</Text></Div>
                                <Textarea top="Приветственное сообщение в диалоге"
                                          value={this.state.welcome_message}
                                          onChange={(e) => this.setState({welcome_message: e.target.value})}
                                />

                            </SimpleCell>
                            <SimpleCell>
                                <Div>Прикрепленные фотографии:</Div>
                                   {this.state.photos.length>0 ? this.state.photos.map((photo, i) => {

                                // Return the element. Also pass key
                                return (<div>{ photo }    <a   onClick={(e) =>this.handlePhotoDelete(e,i)}
                                                                >Удалить</a></div>)
                                 }) : null}
                            <File top="Загрузите фото"

                                  before={<Icon24Document/>}
                                  controlSize="xl"
                                  onChange={(e) => this._handlePhotoUpload(e)}
                            />
                            </SimpleCell>

                            <SimpleCell>
                                <Div>Прикрепленные файлы:</Div>
                                {this.state.files.length>0 ? this.state.files.map((file, i) => {

                                    // Return the element. Also pass key
                                    return (<div><a target="_blank" href={file.file_url}>{ file.name }</a>    <a   onClick={(e) =>this.handleFileDelete(e,i)}
                                    >Удалить</a></div>)
                                }) : null}
                                <File top="Загрузите файл"

                                      before={<Icon24Document/>}
                                      controlSize="xl"
                                      onChange={(e) => this._handleFileUpload(e)}
                                />
                            </SimpleCell>

                            {/*<SimpleCell>*/}
                            {/*    <File top="Загрузите обложку (jpg)"*/}
                            {/*          before={<Icon24Document/>}*/}
                            {/*          controlSize="xl"*/}
                            {/*          onChange={(e) => this._handleImageChange(e)}*/}
                            {/*    />*/}
                            {/*</SimpleCell>*/}
                        </TabPanel>
                        <TabPanel index={2} value={this.state.tab}>

                                <Group mode="plain">

                                    <SimpleCell
                                        after={<Switch checked={this.state.disable_verify}
                                                       onChange={this.handleDisableVerify}/>}><Text
                                        weight="regular">Отключить верификацию</Text>
                                    </SimpleCell>
                                    {this.state.disable_verify ? null:
                                        <SimpleCell
                                            after={<Input
                                                type='text'
                                                value={this.state.bot_name}
                                                onChange={(e) => this.setState({bot_name: e.target.value})}
                                            />}>

                                            <Text weight="regular">Имя TG бота</Text>
                                        </SimpleCell>
                                    }
                                </Group>
                                    <Group mode="plain">
                                        <SimpleCell
                                            after={<Switch checked={this.state.iscoverdynamic}
                                                           onChange={this.handleIscoverDynamic}/>}><Text
                                            weight="regular">Включить динамическую смену обложки</Text>
                                        </SimpleCell>
                                    </Group>
                                    <Group mode="plain">

                                    <SimpleCell
                                        after={<Switch checked={this.state.disable_mlm}
                                                       onChange={this.handleDisableMlm}/>}><Text
                                        weight="regular">Отключить mlm</Text>
                                    </SimpleCell>

                                </Group>

                                <Group mode="plain">
                                    <SimpleCell
                                        after={<Select type="text" value={this.state.amo_user_id}
                                                       onChange={this.handleAmoUser}>
                                            <option key='' value="">выберите из списка</option>
                                            {
                                                this.state.amo_data.users.map((element) => {
                                                    return <option key={element.id}
                                                                   value={element.id}>{element.name}</option>;

                                                })}
                                        </Select>}><Text weight="regular">Ответственный в AMO</Text>
                                    </SimpleCell>
                                    <SimpleCell after={<Select value={this.state.amo_pipeline_id} type="text"
                                                               onChange={this.handleAmoPipeline}>
                                        <option value="">выберите из списка</option>
                                        {
                                            this.state.amo_data.pipelines.map((element) => {

                                                return <option value={element.id}>{element.name}</option>;
                                            })}
                                    </Select>}><Text weight="regular">Воронка в AMO</Text>
                                    </SimpleCell>

                                    <SimpleCell
                                        after={<Select value={this.state.amo_status_id} type="text"
                                                       onChange={this.handleAmoStatus}>
                                            <option value="">выберите из списка</option>
                                            {
                                                this.state.amo_data.statuses.map((element) => {
                                                    if (element.pipeline_id == this.state.amo_pipeline_id) {

                                                        return <option value={element.id}>{element.name}</option>;
                                                    }
                                                })}
                                        </Select>}><Text weight="regular">Статус в AMO</Text>
                                    </SimpleCell>
                                </Group>
                                <Group mode="plain">
                                    <SimpleCell
                                        after={<Switch checked={this.state.active} onChange={this.handleActive}/>}
                                    >
                                        <Text weight="regular">Активно</Text>
                                    </SimpleCell>
                                </Group>
                                <Group mode="plain">
                                    <SimpleCell
                                        after={<Switch checked={this.state.custom_btn}
                                                       onChange={() => this.setState({custom_btn: !this.state.custom_btn})}/>}
                                    >
                                        <Text weight="regular">Использовать кастомную кнопку</Text>
                                    </SimpleCell>
                                    {this.state.custom_btn ?
                                        <>
                                            <SimpleCell after={<Input type='text' value={this.state.custom_btn_name}
                                                                      onChange={(e) => this.setState({custom_btn_name: e.target.value})}/>}>
                                                <Text weight="regular">Имя кнопки</Text>
                                            </SimpleCell>
                                            <SimpleCell after={<Input type='text' value={this.state.custom_btn_link}
                                                                      onChange={(e) => this.setState({custom_btn_link: e.target.value})}/>}>
                                                <Text weight="regular">Ссылка</Text>
                                            </SimpleCell>
                                        </>

                                        : null}
                                </Group>

                        </TabPanel>
                        <Group>
                            <Div>
                                {this.state.isError ? <FormError error={[this.state.errorText]}/> : null}
                            </Div>
                            <Div style={{display: 'flex'}}>
                                <Button size="xl">Сохранить</Button>
                                <Button size="xl" mode="destructive" onClick={this.handleDelete}>Удалить</Button>
                            </Div>
                        </Group>

                    </FormLayout>
                }

            </FormLayoutGroup>
        </Panel>
    }

}
