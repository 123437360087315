import React from 'react';
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import PanelHeaderContent from "@vkontakte/vkui/dist/components/PanelHeaderContent/PanelHeaderContent";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import bridge from '@vkontakte/vk-bridge';
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import Icon28MessageOutline from "@vkontakte/icons/dist/28/message_outline";
import List from "@vkontakte/vkui/dist/components/List/List";
import {Cell, Search} from "@vkontakte/vkui";
import Switch from "@vkontakte/vkui/dist/components/Switch/Switch";
import Button from "@vkontakte/vkui/dist/components/Button/Button";

export default class FriendList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            friendList: [],
            isLoading: true,
            searchStr: '',
            searchList: []
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        bridge.send("VKWebAppGetAuthToken", {app_id: Number(process.env.REACT_APP_VK_APP_ID), scope: "friends"}).then(data => {
            if (this._isMounted) {
                this.props.dispatch({
                    type: 'HANDLE_SET_ACCESS_TOKEN',
                    access_token: data.access_token
                });

                bridge.send('VKWebAppCallAPIMethod', {
                    method: 'friends.get',
                    params: {
                        user_id: this.props.store.userReducer.userInfo.id,
                        fields: 'nickname, photo_100, contacts',
                        access_token: this.props.store.userReducer.userInfo.access_token,
                        v: '5.124'
                    }
                }).then((data) => {
                    this.props.dispatch({
                        type: 'HANDLE_SET_FRIENDS',
                        friendList: data.response.items
                    });
                    this.setState({
                        isLoading: false,
                        friendList: this.props.store.userReducer.userInfo.friendList
                    });
                });

            }
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    Friends = () => {
        if(this.state.isLoading){
            return <ScreenSpinner/>;
        }
        return this.state.friendList.map((item => {
            const full_name = `${item.first_name} ${item.last_name}`;
            return <Cell asideContent={<Switch/>} before={<Avatar size={48} src={item.photo_100} />} after={<Icon28MessageOutline />}>{full_name}</Cell>;
        }));
    }

    handleSearch = (e) => {
        this.setState({
            searchStr: e.target.value
        });

        let filteredObject = this.props.store.userReducer.userInfo.friendList.filter(function(item)
        {
            let fullName = `${item.first_name} ${item.last_name}`;
            return fullName.indexOf(e.target.value) > -1

        });
        console.log(filteredObject);
        this.setState({
            friendList: filteredObject
        });

    }

    render() {
        return <Panel id={"friendList"}>
            <PanelHeader><PanelHeaderContent
                before={<Avatar size={36} src={this.props.store.userReducer.userInfo.photo_100}/>}
            >Выбирете друзей, ктотрым вы хотите отправить форму участника.</PanelHeaderContent></PanelHeader>
            <Div>
                <Cell asideContent={<Button onClick={() => this.props.navigator.goBack()}>Отправить</Button>}  ><Button mode="secondary" onClick={() => this.props.navigator.goBack()}>Назад</Button></Cell>
            </Div>
            <Div>
                <Search value={this.state.searchStr} onChange={this.handleSearch} after={null}/>
            </Div>
            <Div>
                <Group>
                    <List>
                    <this.Friends/>
                    </List>
                </Group>
            </Div>
        </Panel>;
    }
}
