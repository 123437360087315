import React from 'react';
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import API from "../utils/API";
import {Link} from "@vkontakte/vkui";
import Icon24BrowserBack from "@vkontakte/icons/dist/24/browser_back";
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Grid from "@material-ui/core/Grid";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import fileDownload from 'js-file-download'
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import EqualizerIcon from "@material-ui/icons/Equalizer";



export default class UserContest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            users: [],
            selection: []
        }
    }

    columns = () => {
        return [
            { field: 'name', headerName: 'Имя', width: 130 },
            { field: 'city', headerName: 'Город', width: 130 },
            { field: 'phone', headerName: 'Телефон', width: 130 },
            { field: 'source', headerName: 'Источник', width: 50 },
            { field: 'ref', headerName: 'REF', width: 100 },
            { field: 'mlmref', headerName: 'MLM', width: 100 }
        ]
    }

    componentDidMount() {
        API.get('/contest/' + this.props.navigator.params.id + '/users/',{
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            this.setState({
                isLoading: false,
                users: res.data.users
            });
        });
    }

    ExportUsers = () => {
        this.setState({
            isLoading: true,
        });
        API.post('/contest/export/users/', {
            users: this.state.selection
        },{
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            fileDownload(res.data.result, 'users.csv');
            this.setState({
                isLoading: false,
            });
        });
    }

    render() {
        return <Panel id={"user_contest"}>
            {this.props.store.userReducer.userInfo.is_admin ?
                <Div>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Link onClick={() => this.props.navigator.go("contests")}><Icon24BrowserBack/></Link>
                    </Grid>
                    <Grid item xs>
                        <Link onClick={() => this.ExportUsers()}><GetAppIcon/></Link>
                    </Grid>
                    <Grid item xs>
                        <Link onClick={() => this.props.navigator.go("contests")}><MailOutlineIcon/></Link>
                    </Grid>
                    <Grid item xs>
                        <Link onClick={() => this.props.navigator.go("stats", {id: this.props.navigator.params.id})}><EqualizerIcon/></Link>
                    </Grid>
                </Grid>

                </Div> : null}
            {this.state.isLoading? <ScreenSpinner/>:
                <div style={{height: 700, width: '100%'}}>
                    <DataGrid
                        rows={this.state.users}
                        columns={this.columns()}
                        pageSize={50}
                        rowsPerPageOptions={[100, 500, { value: -1, label: 'All' }]}
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                            this.setState({
                               selection: newSelection.selectionModel
                            });
                        }}
                        selectionModel={this.state.selection}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        />
                </div>
            }

        </Panel>
    }
}
