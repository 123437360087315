import React from 'react';
import {FormStatus} from "@vkontakte/vkui";

export default function FormError(props) {
    return <FormStatus header="Ошибка" mode="error" style={{marginBottom: 10}}>
        {props.error.map((item, index) => {
            return <p key={index}>{item}</p>
        })}
    </FormStatus>
}
