import React from 'react';
import bridge from '@vkontakte/vk-bridge';
import {Stack, Page} from "vkui-navigator/dist";
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';
import Form from "./panels/Form";
import Home from "./panels/Home";
import FriendList from "./panels/FriendList";
import Contests from "./panels/Contests";
import CreateContest from "./panels/CreateContest";
import API from "./utils/API";
import ManageContest from "./panels/manageContest";
import FormError from "./panels/FormError";
import UserContest from "./panels/userContest";
import StatsContest from "./panels/statsContest";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            isLoading: true,
            home_panel: 'form',
            isIssetContest: true,
            isActive: true,
            urlParams: window.location.search === '' ? '?' : window.location.search
        }

        this.hash = window.location.hash.substring(1);

        var arr = this.hash.split("&");

        if (arr[1]) {
            this.hash = arr[1];
        } else {
            this.hash = "";
        }

    //    console.log('test');
     //   console.log(process.env);
     //  console.log(process.env.REACT_APP_VK_APP_ADMINS);



    }

    componentDidMount() {
        this.props.dispatch({
            type: 'HANDLE_SET_HASH',
            hash: this.hash

        });

        var path_name = window.location.pathname;

        var contest_id;

        if (path_name.includes("/id")) {
            contest_id = path_name;
            contest_id = contest_id.replace('/id', '');
            this.state.urlParams = "?id=" + contest_id;
        }

        if (window.location.hash) {
            var hash_string = document.location.hash;
            if (hash_string.includes("/id")) {
                var arr = hash_string.split("&");

                contest_id = arr[0];
                contest_id = contest_id.replace('#/id', '');

                if (this.state.urlParams.includes("?")) {
                    this.state.urlParams += "&id=" + contest_id;
                } else {
                    this.state.urlParams = "?id=" + contest_id;
                }

                if(contest_id && arr.includes('panel=manage_contest')){
                    this.setState({home_panel: 'manage_contest'})
                }else if(contest_id && arr.includes('panel=form')){
                    this.setState({home_panel: 'form'})
                }

            }
        }

        API.get(process.env.REACT_APP_API_URL + '/app/launch' + this.state.urlParams).then(res => {
            if (res.data.vk_launch) {
                this.handleSetUserInfo();
            }

            this.props.dispatch({
                type: 'HANDLE_SET_LAUNCH',
                mode: res.data.vk_launch,
                contest: res.data.contest,
                cities: res.data.cities,
                token: res.data.token,
                is_admin: res.data.is_admin
            });

            if(res.data.is_admin && contest_id === undefined){
                this.setState({home_panel: 'contests'})
            }

            let issetContest = res.data.contest === null ? false : true;
            let isActive = false;
            if (res.data.contest !== null) {
                isActive = res.data.contest.active;
            }

            this.setState({
                isLoading: false,
                isIssetContest: issetContest,
                isActive: isActive
            });

        });
    }

    handleSetUserInfo = () => {
        if (this.props.store.userReducer.userInfo.id === null) {
            bridge.send("VKWebAppGetUserInfo").then((data) => {

                this.props.dispatch({
                    type: 'HANDLE_SET_USER_INFO',
                    userData: data
                });
                this.setState({
                    userInfo: data,
                    isLoading: false
                });
            });

            this.props.dispatch({
                type: 'HANDLE_SET_GROUP_ID',
                data: new URLSearchParams(this.state.urlParams).get('vk_group_id')
            });
        }
    }

    render() {
        console.log(this.state.home_panel);
        if (this.state.isLoading) {
            return <ScreenSpinner/>;
        }
        if (this.state.isActive === false && this.props.store.userReducer.userInfo.is_admin === false) {
            return <FormError error={['Конкурс не активен']}/>
        }
        if (this.state.isIssetContest === false && this.props.store.userReducer.userInfo.is_admin === false) {
            return <FormError error={['Конкурс не существует']}/>
        }
        return <Stack activePage="page1">
            <Page id="page1"
                  activePanel={this.state.home_panel}>
                <Home id='home' store={this.props.store} dispatch={this.props.dispatch}/>
                <Form id='form' store={this.props.store} dispatch={this.props.dispatch}/>
                <Contests id='contests' store={this.props.store} dispatch={this.props.dispatch}/>
                <FriendList id='friendList' store={this.props.store} dispatch={this.props.dispatch}/>
                <CreateContest id={'create_contest'} store={this.props.store} dispatch={this.props.dispatch}/>
                <ManageContest id={'manage_contest'} store={this.props.store} dispatch={this.props.dispatch}/>
                <UserContest id={'user_contest'} store={this.props.store} dispatch={this.props.dispatch} />
                <StatsContest id={'stats'} store={this.props.store} dispatch={this.props.dispatch} />
            </Page>
        </Stack>
    }
}



