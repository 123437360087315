import React from 'react';
import formImgHeader from "../img/formHeader.png";

const FormHead = React.memo( (props) => {
    var img=formImgHeader;
    if(props.cover && props.cover.length>1){
        img=props.cover+"?"+Math.random();
    }

    return <img src={img} style={{maxWidth: '100%'}} alt="freebilet" />;
}
)

export default FormHead;
