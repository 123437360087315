import React from 'react';
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import API from "../utils/API";
import {FormStatus, Link, SimpleCell} from "@vkontakte/vkui";
import Grid from "@material-ui/core/Grid";
import Icon24BrowserBack from "@vkontakte/icons/dist/24/browser_back";
import GetAppIcon from "@material-ui/icons/GetApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import Chart from "react-google-charts";
import Header from "@vkontakte/vkui/dist/components/Header/Header";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import DayPicker, {DateUtils} from "react-day-picker";
import {Helmet} from "react-helmet";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/ru';
import downloadCSV from "../utils/createCSV";


export default class StatsContest extends React.Component {

    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = {
            isLoading: true,
            users: [],
            allLeads: 0,
            mlmLeads: 0,
            from: undefined,
            to: undefined,
        }
    }

    getInitialState() {
        return {
            from: this.state.from,
            to: this.state.to,
        };
    }

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.getInitialState());
        this.setState({
            from: range.from,
            to: range.to
        });
    }

    handleResetClick() {
        this.setState({
            from: undefined,
            to: undefined
        });
    }

    componentDidMount() {
        API.get('/contest/' + this.props.navigator.params.id + '/stats/', {
            headers: {
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            this.setState({
                isLoading: false,
                users: res.data.chart,
                allLeads: res.data.all_leads,
                mlmLeads: res.data.mlm_leads,
                from: parseDate(res.data.range.from, 'YYYY-MM-DD', 'ru'),
                to: parseDate(res.data.range.to, 'YYYY-MM-DD', 'ru'),
            });
        });
    }

    handleRangeStat = () => {
        this.setState({isLoading: true})
        API.post('/contest/' + this.props.navigator.params.id + '/stats/', {
            range: {
                from: formatDate(this.state.from, 'YYYY-MM-DD'),
                to: formatDate(this.state.to, 'YYYY-MM-DD')
            }
        },{
            headers: {
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            this.setState({
                isLoading: false,
                users: res.data.chart,
                allLeads: res.data.all_leads,
                mlmLeads: res.data.mlm_leads,
                from: parseDate(res.data.range.from, 'YYYY-MM-DD', 'ru'),
                to: parseDate(res.data.range.to, 'YYYY-MM-DD', 'ru'),
            });
        });
    }

    ExportStats = () => {
        downloadCSV({
            filename: "stats.csv",
            data: this.state.users,
            columns: ["date","leads", "mlm leads"]
        });
    }

    render() {
        const from = this.state.from;
        const to = this.state.to;

        const modifiers = {start: from, end: to};

        let range_notice = null;
        if(this.state.from === undefined && this.state.to === undefined) {
            range_notice = 'Выберите начальную дату.';
        }else if(this.state.from !== undefined && this.state.to === undefined){
            range_notice = 'Выберите конечную дату.';
        }else if(this.state.from !== undefined && this.state.to !== undefined){
            range_notice = 'Выбрано с ' + this.state.from.toLocaleDateString() +' по ' + this.state.to.toLocaleDateString();
        }

        return <Panel id={"stats"}>
            {this.props.store.userReducer.userInfo.is_admin ?
                <Div>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Link onClick={() => this.props.navigator.go("contests")}><Icon24BrowserBack/></Link>
                        </Grid>
                        <Grid item xs>
                            <Link onClick={() => this.ExportStats()}><GetAppIcon/></Link>
                        </Grid>
                        <Grid item xs>
                            <Link onClick={() => this.props.navigator.go("contests")}><MailOutlineIcon/></Link>
                        </Grid>
                        <Grid item xs>
                            <Link
                                onClick={() => this.props.navigator.go("stats", {id: this.props.navigator.params.id})}><EqualizerIcon/></Link>
                        </Grid>
                    </Grid>

                </Div> : null}
            {this.state.isLoading ? <ScreenSpinner/> :
                <div style={{height: 700, width: '100%'}}>
                    <Group header={<Header mode="secondary">Статистика</Header>}>
                        <SimpleCell before={'Всего лидов'} after={this.state.allLeads}/>
                        <SimpleCell before={'МЛМ лидов'} after={this.state.mlmLeads}/>
                    </Group>
                    <Group header={<Header mode="secondary">Промежуток статистики</Header>}>
                        <SimpleCell before={range_notice} after={
                            <>
                                <Button size={'m'} onClick={this.handleRangeStat}>Показать</Button>
                                <Button size={'m'} mode="secondary" onClick={this.handleResetClick}>Сбросить</Button>
                            </>
                        }
                        />
                        <DayPicker
                            className="Selectable"
                            numberOfMonths={this.props.numberOfMonths}
                            selectedDays={[from, {from, to}]}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                            localeUtils={MomentLocaleUtils} locale={'ru'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                        />
                    </Group>
                    <Group header={<Header mode="secondary">График статистики</Header>}>
                    {this.state.users.length > 1?
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="LineChart"
                        loader={<ScreenSpinner/>}
                        data={this.state.users}
                        options={{
                            hAxis: {
                                title: 'Date',
                            },
                            vAxis: {
                                title: 'Leads',
                            },
                            series: {
                                1: {curveType: 'function'},
                            },
                        }}
                    />:
                        <SimpleCell>
                            <FormStatus header="Нет данных для отображения" mode="error">
                                Укажите другие даты
                            </FormStatus>
                        </SimpleCell>
                    }
                    </Group>
                    <Helmet>
                        <style>{`
  .DayPicker, .DayPicker-wrapper {
    width:100%;
    padding-bottom: 0;
  }
  
  .DayPicker-Months{
    margin: 0 3em;
  }
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
                    </Helmet>
                </div>
            }

        </Panel>
    }
}
