import React from "react";

export default class CityList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            this.props.cities.map((item, index) => {
                if (['Другой', 'ДВДИСТ','Москва-Балашиха','Москва-Мытищи'].indexOf(item.name) === -1){
                    return <option value={item.name} key={index}>{item.name}</option>
                }
            })
        );
    }

}
