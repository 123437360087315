import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default class TabPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { children, value, index} = this.props;

        return <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (

                   children

            )}
        </div>
    }
}
