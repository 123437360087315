let initState = {
    app:{
        mode: 'vk',
        contest: [],
        hash: null,
        group_id: null,
        cities: []
    },
    userInfo: {
        id: null,
        full_name: null,
        phone: null,
        city: null,
        photo_100: null,
        access_token: null,
        is_admin: false,
        friendList: [],
        allow_messages: false,
        uuid: null,
        token: null
    }
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'HANDLE_SET_USER_INFO':
            state.userInfo.full_name = action.userData.first_name + ' ' + action.userData.last_name;
            state.userInfo.city = action.userData.city.title;
            state.userInfo.photo_100 = action.userData.photo_100;
            state.userInfo.id = action.userData.id;
            return state;
        case 'HANDLE_SET_USER_PHONE':
            state.userInfo.phone = action.data;
            return state;
        case 'HANDLE_SET_FULL_NAME':
            state.userInfo.full_name = action.data;
            return state;
        case 'HANDLE_SET_USER_CITY':
            state.userInfo.city = action.data;
            return state;
        case 'HANDLE_SET_ACCESS_TOKEN':
            state.userInfo.access_token = action.access_token;
            return state;
        case 'HANDLE_SET_FRIENDS':
            state.userInfo.friendList = action.friendList;
            return state
        case 'HANDLE_SET_HASH':
            state.app.hash = action.hash;
            return state;
        case 'HANDLE_SET_ALLOW':
            state.userInfo.allow_messages = action.data
            return state;
        case 'HANDLE_SET_GROUP_ID':
            state.app.group_id = action.data
            return state;
        case 'HANDLE_SET_LAUNCH':
            action.mode? state.app.mode = 'vk' : state.app.mode = 'web';
            state.app.contest = action.contest;
            state.app.cities = action.cities;
            state.userInfo.token = action.token;
            state.userInfo.is_admin = action.is_admin;
            return state;
        case 'HANDLE_SET_UUID':
            state.userInfo.uuid = action.data;
            return state;
        default:
            return state;
    }
};

export default userReducer;
